import { Route } from '@angular/router';
import { QuestOverviewComponent } from './components/quest-overview/quest-overview.component';
import { HomeComponent } from './home/home.component';

export const appRoutes: Route[] = [
  { path: '', component: HomeComponent },
  {
    path: 'quest',
    component: QuestOverviewComponent,
  },
  {
    path: 'quest/:id',
    component: QuestOverviewComponent,
  },
];
