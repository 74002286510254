<table class="min-w-full bg-white border border-gray-300">
  <thead>
    <tr>
      <th class="py-2 px-4 border-b border-gray-300 text-center">Slug</th>
      <th class="py-2 px-4 border-b border-gray-300 text-center">Title</th>
      <th class="py-2 px-4 border-b border-gray-300 text-center">
        Description
      </th>
      <th class="py-2 px-4 border-b border-gray-300 text-center">Location</th>
      <th class="py-2 px-4 border-b border-gray-300 text-center">Is Public</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let quest of quests"
      (click)="onQuestClick(quest.quest_id)"
      class="hover:bg-gray-100"
    >
      <td class="py-2 px-4 border-b border-gray-300 text-center">
        {{ quest.slug }}
      </td>
      <td class="py-2 px-4 border-b border-gray-300 text-center">
        {{ quest.title }}
      </td>
      <td class="py-2 px-4 border-b border-gray-300 text-center">
        {{ quest.description }}
      </td>
      <td class="py-2 px-4 border-b border-gray-300 text-center">
        {{ quest.location }}
      </td>
      <td class="py-2 px-4 border-b border-gray-300 text-center">
        {{ quest.is_public ? 'Yes' : 'No' }}
      </td>
    </tr>
  </tbody>
</table>
