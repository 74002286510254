import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENVIRONMENT } from '@architex-frontend/util';
import { Observable } from 'rxjs';

export const apiKeyInterceptorFn: HttpInterceptorFn = (
  req: any,
  next: any
): Observable<any> => {
  const environment = inject(ENVIRONMENT);
  req = req.clone({
    setHeaders: { ApiKey: environment.apiKey },
  });
  return next(req);
};
