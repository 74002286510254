import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DataService } from '@architex-frontend/data';

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  title = 'Architex';
  constructor(private dataService: DataService) {
    this.dataService.healthCheck().subscribe();
  }
}
