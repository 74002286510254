<div *ngIf="questForm" class="container mx-auto p-4">
  <form [formGroup]="questForm" (ngSubmit)="onSubmit()">
    <app-quest [questForm]="questForm"></app-quest>

    <!--
    <div formArrayName="events" class="mt-4">
      <div
        *ngFor="let event of events.controls; let i = index"
        [formGroupName]="i"
      >
        <app-event [eventForm]="event"></app-event>
      </div>
    </div>

    -->
    <button type="button" (click)="addEvent({})" class="btn btn-primary">
      Add Event
    </button>
    <button type="submit" class="btn btn-primary">Submit</button>
  </form>
</div>
