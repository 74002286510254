import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { QuestListComponent } from '../components/quest-list/quest-list.component';
import { Quest } from '../models/api.models';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, QuestListComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit {
  quests: Quest[] = [];
  constructor(private dataService: DataService, private router: Router) {}

  ngOnInit(): void {
    this.dataService
      .getQuests()
      .pipe(
        tap((res) => {
          this.quests = res;
        })
      )
      .subscribe();
  }

  goToQuest(questId: string) {
    //
  }

  addQuest() {
    this.router.navigate(['quest']);
  }
}
