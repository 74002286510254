import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Quest } from 'src/app/models/api.models';

@Component({
  selector: 'app-quest-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './quest-list.component.html',
  styleUrl: './quest-list.component.css',
})
export class QuestListComponent {
  @Input() quests: Quest[] = [];
  @Output() questClicked = new EventEmitter<string>();

  onQuestClick(questId: string) {
    this.questClicked.emit(questId);
  }
}
