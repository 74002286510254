import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Quest } from 'src/app/models/api.models';
import { EventComponent } from '../event/event.component';
import { QuestComponent } from '../quest/quest.component';

@Component({
  selector: 'app-quest-overview',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, EventComponent, QuestComponent],
  templateUrl: './quest-overview.component.html',
  styleUrl: './quest-overview.component.css',
})
export class QuestOverviewComponent implements OnInit {
  questForm!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.createForm();
  }

  get events(): FormArray {
    return this.questForm.get('events') as FormArray;
  }

  onSubmit() {
    const quest: Quest = this.questForm.value;
    console.log('Quest data:', quest);
  }

  addEvent(event: any) {
    const eventForm = this.fb.group({
      event_id: ['', Validators.required],
      event_type_id: [0, Validators.required],
      is_poe: [false, Validators.required],
      is_conclusion: [false, Validators.required],
      score: [0, Validators.required],
      title: ['', Validators.required],
      message: [null],
      coordinate: this.fb.group({
        coordinate_id: [''],
        latitude: [0],
        longitude: [0],
        radius: [null],
      }),
      image_link: [null],
      routes: this.fb.array([]),
      hints: this.fb.array([]),
      quest_ids_unlocked: [[], Validators.required],
      segue_quest_id: [null],
    });
    this.events.push(eventForm);
  }

  createForm = () => {
    this.questForm = this.fb.group({
      quest_id: ['', Validators.required],
      version: [0, Validators.required],
      api_version: ['', Validators.required],
      user_id: [null],
      slug: ['', Validators.required],
      title: ['', Validators.required],
      subtitle: [null],
      description: ['', Validators.required],
      location: [null],
      city: [null],
      state: [null],
      coordinate: this.fb.group({
        coordinate_id: [''],
        latitude: [0],
        longitude: [0],
        radius: [null],
      }),
      image_link: [null],
      quest_open_date: [null],
      quest_close_date: [null],
      quest_open_hour: [null],
      quest_close_hour: [null],
      is_public: [false, Validators.required],
      events: this.fb.array([]),
      default_event_id: ['', Validators.required],
      inserted_on: [new Date(), Validators.required],
    });
  };
}
