<div [formGroup]="questForm" class="grid grid-cols-1 gap-4">
  <label class="block">
    <span class="text-gray-700">Quest ID</span>
    <input type="text" formControlName="quest_id" class="mt-1 block w-full" />
  </label>
  <label class="block">
    <span class="text-gray-700">Version</span>
    <input type="number" formControlName="version" class="mt-1 block w-full" />
  </label>
  <label class="block">
    <span class="text-gray-700">API Version</span>
    <input
      type="text"
      formControlName="api_version"
      class="mt-1 block w-full"
    />
  </label>
  <label class="block">
    <span class="text-gray-700">Slug</span>
    <input type="text" formControlName="slug" class="mt-1 block w-full" />
  </label>
  <label class="block">
    <span class="text-gray-700">Title</span>
    <input type="text" formControlName="title" class="mt-1 block w-full" />
  </label>
  <label class="block">
    <span class="text-gray-700">Description</span>
    <textarea
      formControlName="description"
      class="mt-1 block w-full"
    ></textarea>
  </label>
  <label class="block">
    <span class="text-gray-700">Is Public</span>
    <input type="checkbox" formControlName="is_public" class="mt-1" />
  </label>
</div>
